import { useState } from 'preact/hooks'
import { FunctionComponent } from 'preact'

interface AccordionProps {
  title: string
  children: preact.ComponentChildren
}

export const Accordion: FunctionComponent<AccordionProps> = ({
  title,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div>
      <button class="accordion" onClick={() => setIsOpen((prev) => !prev)}>
        {title} {isOpen ? '▲' : '▼'}
      </button>
      {isOpen && <div class="accordion-content">{children}</div>}
    </div>
  )
}
