import { FunctionComponent } from 'preact'
import { Empty } from '~/common'

export const Resources: FunctionComponent<Empty> = () => (
  <>
    <h2 class="centered-text">Interested in using our EDC database?</h2>
    <img
      src="/assets/integrate.svg"
      class="evidence-svg"
      alt="Intrate with the endoscreen EDC database for free!"
    />
    <h3 class="centered-text">
      Endoscreen uses a proprietary database of endocrine-disrupting chemicals.
      Because we want to increase awareness of these harmful chemicals, you can{' '}
      <a
        href="mailto:contact@endoscreen.org?subject=API%20Request"
        style="color: black;"
      >
        request access
      </a>{' '}
      to our API at no cost!
    </h3>
    <div class="centered-text">
      Please include information about yourself and what you'd like to use the
      database for.
    </div>
    <div class="centered-text">
      Read our{' '}
      <a href="/about" style="color: black;">
        terms and conditions
      </a>{' '}
      for more information.
    </div>
    <br />
    <br />

    <div id="howto-section" class="centered-text">
      <h2>How to use the Endoscreen label scanner</h2>
      Using Endoscreen to scan your products is easy!{' '}
    </div>
    <br />

    <h4 class="centered-text">
      Start by making sure the ingredient list is fully visible in the camera's
      view port.{' '}
    </h4>
    <div class="centered-text">
      Our scanner can read text under many conditions, but the clearer the text
      is, the better!
    </div>
    <img
      src="/assets/example_picture.svg"
      class="example-svg"
      alt="Use your phone camera to capture a photo of the products ingredient list."
    />
    <div class="centered-text">
      From here, you can select "retake" to take another picture, or "analyze"
      to scan for EDCs.
    </div>
    <br />
    <h3 id="camera-trouble" class="centered-text">
      On iPhone or iPad? Use the "Upload image" tab to take a photo!
    </h3>
    <h4 class="centered-text">iPhone users: </h4>
    <div>
      Select “choose file” under the upload image tab to access your phones
      camera and take a picture of your product’s ingredient list.
    </div>
    <h4 id="upload" class="centered-text">
      All users:
    </h4>
    <div>
      Select "choose file" to upload images of ingredient lists saved from the
      web or on your camera roll.{' '}
    </div>
    <img
      src="/assets/iphone.svg"
      class="reliability-svg"
      alt="iPhone camera is accessible under the upload tab."
    />
    <div class="centered-text">
      Camera trouble? Ensure browser settings allow camera access!
    </div>
    <img
      src="/assets/iphone2.svg"
      class="reliability-svg"
      alt="Allow camera access on your browser."
    />
    <div class="centered-text">
      Once you have taken or selected a photo, click "Upload Photo" to scan for
      EDCs.
    </div>
    <br />
    <h3 id="text" class="centered-text">
      Looking to copy and paste? Use the "Enter Text" tab!
    </h3>
    <div class="centered-text">
      Many websites provide lists of ingredients on their product web pages.
      Simply copy the text and paste into the text box to analyze.{' '}
    </div>
    <div class="centered-text">
      If you want to manually enter chemicals, make sure they are separated by a
      comma like below:
    </div>
    <img
      src="/assets/enter_text.svg"
      class="reliability-svg"
      alt="Use enter text to copy and paste from websites."
    />
    <br />
    <div class="centered-text">
      {' '}
      If you have trouble using our scanner, contact us to{' '}
      <a
        href="mailto:contact@endoscreen.org?subject=Bug%20Report&body=Please%20describe%20the%20problem%20you%20are%20encountering."
        style="color: black;"
      >
        Report a Bug
      </a>
      .
    </div>
    <br />
  </>
)
