import { JSX, FunctionComponent, toChildArray } from 'preact'
import { Some, None, Option } from 'prelude-ts'
import { useCallback, useState } from 'preact/hooks'
import { Col, Row } from './flexbox'

import './Tabs.scss'

const optionIndexOf = <T,>(
  list: Readonly<Array<T>>,
  fn: (arg0: T) => boolean
): Option<number> => {
  const index = list.findIndex(fn)
  return index === -1 ? new None() : new Some(index)
}

type Props = {
  tabs: Readonly<Array<{ name: string; pathname: string }>>
  active: string | undefined
}
export const Tabs: FunctionComponent<Props> = ({ tabs, active, children }) => {
  const buttonIds = tabs.map(
    (tab) => `tab-button-${tab.name.replace(/\s+/, '-')}`
  )

  const defaultActiveIdx = Option.of(active)
    .flatMap((pathname) =>
      optionIndexOf(tabs, (tab) => tab.pathname === pathname)
    )
    .getOrElse(0)
  const [activeIdx, setActiveIdx] = useState(defaultActiveIdx)
  const onClick = useCallback(
    (evt: JSX.TargetedMouseEvent<HTMLButtonElement>) => {
      const newIdx = buttonIds.indexOf(evt.currentTarget.id)
      window.history.pushState(null, '', tabs[newIdx].pathname)
      setActiveIdx(newIdx)
    },
    [setActiveIdx, buttonIds, tabs]
  )

  console.log({ tabs, buttonIds, defaultActiveIdx, active })

  return (
    <Col className="tabs">
      <Row className="tab-buttons">
        {tabs.map((tab, idx) => (
          <button
            key={buttonIds[idx]}
            id={buttonIds[idx]}
            onClick={onClick}
            disabled={idx === activeIdx}
          >
            {tab.name}
          </button>
        ))}
      </Row>
      {toChildArray(children).map((child, idx) =>
        idx === activeIdx ? (
          <div key={`tab-content-${idx}`} className="tab-content">
            {child}
          </div>
        ) : null
      )}
    </Col>
  )
}
