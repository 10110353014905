if (process.env.NODE_ENV === 'development') {
  // Must use require here as import statements are only allowed
  // to exist at top-level.
  require('preact/debug')
}

import { render } from 'preact'
import { App } from './app'

import './reset.scss'

declare global {
  interface Window {
    DEPLOY_ENV: 'dev' | 'qa' | 'production'
  }
}

if (window.DEPLOY_ENV === 'dev') {
  new EventSource('/esbuild').addEventListener('change', () =>
    location.reload()
  )
}

render(<App />, document.body)
