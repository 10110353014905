import { FunctionComponent } from 'preact'
import { Empty } from '~/common'

export const About: FunctionComponent<Empty> = () => (
  <>
    <br />
    <h1 style="text-align:center;">
      <a href="mailto:contact@endoscreen.org" style="color: black;">
        Contact Us
      </a>{' '}
      or{' '}
      <a
        href="mailto:contact@endoscreen.org?subject=Bug%20Report&body=Please%20describe%20the%20problem%20you%20are%20encountering."
        style="color: black;"
      >
        Report a Bug
      </a>
    </h1>
    <br />
    <h2>Terms of Use</h2>

    <p>
      Welcome to Endoscreen, a mobile web application provided by Endoscreen LLC
      ("we," "us," or "our"). This App is designed to scan product labels and
      report scientifically-backed information related to health effects of
      chemicals within those products.
      <br />
      By using the Endoscreen App, you agree to comply with and be bound by
      these Terms of Use ("Terms").
    </p>

    <h3>Intellectual Property</h3>
    <p>
      All content, features, and functionality of the Endoscreen App, including
      but not limited to text, graphics, logos, icons, images, and software, are
      the exclusive property of Endoscreen LLC or its licensors and are
      protected by international copyright laws. The compilation of all content
      within the App is the exclusive property of Endoscreen LLC.
    </p>

    <h3>Endoscreen Database API</h3>
    <p>
      At Endoscreen, we want to increase awareness about the harmful health
      effects of endocrine-disrupting chemicals. In accordance with that goal,
      you are granted a limited, non-exclusive, non-transferable license to
      access and use the Endoscreen Database Application Programming Interface
      ("API") for the purpose of developing, testing, and supporting an
      integration of your application ("Your Application") with the Endoscreen
      EDC Database, strictly in accordance with these Terms of Use.
      <h4>Requisite information requets:</h4>
      By using the Endoscreen Database API, you agree to comply with any
      informational requests from Endoscreen LLC relating to the identity,
      usage, functionality, or security of the API, as well as the nature and
      extent of your data collection, storage, and processing activities. You
      may not use the API without fulfilling our basic informational request
      form, and failure to do will result in termination of access to the API.
      <h4>Modifying content from the API is forbidden.</h4>
      You may not modify, distort, or alter the content or data obtained through
      the Endoscreen API in a way that is misleading, manipulative, or contrary
      to the original intent and representation of such content or data as
      provided by the Endoscreen App. You must preserve the accuracy and
      integrity of the content you receive via our API when presenting it
      through Your Application. <br />
      <br />
      You are solely responsible for ensuring that your use of the Endoscreen
      API is in compliance with all applicable local, state, national, and
      international laws and regulations. <br />
      Endoscreen LLC reserves the right to revoke your API access or terminate
      your license for any reason, including but not limited to your failure to
      comply with these Terms or any violation of applicable laws.
    </p>

    <h3>Health Information and Research Accuracy</h3>
    <p>
      <h4>Endoscreen is not meant to provide medical advice.</h4>The content
      provided by the Endoscreen app is for informational purposes only and is
      not intended to replace or substitute for any professional, medical, or
      legal advice. While we strive to provide accurate, up-to-date information,
      we make no representations or warranties of any kind, express or implied,
      about the completeness, accuracy, reliability, suitability, or
      availability of information provided by the app concerning information
      presented or related graphics contained within the app for any purpose.{' '}
      <br />
      Always seek the advice of your physician, healthcare provider, or other
      qualified health professionals with any questions you may have regarding a
      medical condition. Never disregard professional medical advice or delay in
      seeking it because of something you have read on this app. <br />
      Reliance on any information provided by this app is solely at your own
      risk. The app and its content are provided on an "as-is" basis. We
      expressly disclaim any liability for damages or consequences arising from
      the use or misuse of the information within the app. <br />
      <h4>
        Endoscreen cannot confirm complete accuracy of information presented.
      </h4>
      We expressly disclaim all liability for errors and omissions in the
      content of this app and for the use or interpretation by others of
      information contained herein. By using this app, you agree to not hold us
      responsible for any inaccuracies, mistakes, or reliance on the information
      presented. It is your responsibility to critically evaluate and verify the
      information you find within this app, and assume full responsibility for
      any and all risks associated with such use.{' '}
    </p>

    <h3>Exemption from Liability for Third-Party Companies</h3>
    <p>
      Endoscreen is provided as a service to identify chemicals in various
      products based on publicly available, scientific literature. While we
      strive to provide accurate and up-to-date information, we are not
      responsible for any actions taken or not taken by manufacturers, vendors,
      or other third parties whose products are analyzed on our platform.
      <h4>Limitation of Liability:</h4>
      We do not endorse, represent, or warrant the effectiveness, quality, or
      safety of any products analyzed, featured, or discussed by the Endoscreen
      app. Our service is solely aimed at providing information for educational
      and informational purposes. As such, we are not liable for any harm,
      damage, or adverse effects that may arise from the use of or exposure to
      products containing chemicals that we do or do not identify as potentially
      harmful.
      <h4>No Legal Claims:</h4>
      Any information provided by the Endoscreen app does not constitute legal
      or medical advice, and it should not be used as a basis for making any
      specific legal or health-related decisions. Manufacturers, vendors, and
      other third parties whose products contain chemicals that are identified
      by our service as potentially harmful should not interpret this
      information as constituting legal claims against them.
    </p>

    <h3>Data Privacy Policy</h3>
    <p>
      At Endoscreen LLC, we are committed to safeguarding your privacy and
      ensuring the protection of your data. This Data Privacy Policy explains
      the types of information we collect, how we use it, and your rights
      regarding your data.
      <br />
      <h4>What Information We Collect:</h4>
      We collect non-identifying personal information data such as your age,
      race/ethnicity, and preferred gender identity when you fill out our
      survey. We retain the right to adjust our requested data requests within
      the realm of non-identifying information to include things like geographic
      location. Submission of our survey is tacit consent for the internal usege
      (see below) of that data. We do not keep any images taken using the
      Endoscreen camera functionality, nor will we ever access your device
      without your active knowledge and consent.
      <br />
      <h4>How We Use Your Information:</h4>
      The information you provide during account creation or in our surveys is
      stored securely. We use this data to enhance your user experience and
      improve our services. This data may also be shared anonymously and
      privately with our funding agencies. We reserve the right to work with
      non-identifying data for analytics, research, or other non-profit business
      purposes as we see fit.
      <br />
      <h4>Your Rights and Control:</h4>
      Right to Access: You may request copies of your personal data that we
      hold.
      <br />
      Right to Rectification: If you believe any of your data is inaccurate or
      incomplete, you have the right to ask us to update it.
      <br />
      Right to Erasure: You can request the deletion of your personal data,
      under certain conditions.
      <br />
      Right to Restrict Processing: You have the right to ask us to limit the
      use of your data, under certain conditions.
      <br />
      Right to Object to Processing: You can object to our use of your data,
      under certain conditions.
      <br />
      <h4>Security Measures:</h4>
      Your data is stored securely, and we employ industry-standard measures to
      protect against unauthorized access or disclosure.
      <h4>No Selling or Public Disclosure:</h4>
      We will not sell or make publicly available any of your personal
      information under any circumstance.
      <h4>Updates to This Policy:</h4>
      We may periodically update this Data Privacy Policy. Any changes will be
      posted here, and if significant changes are made, we will provide more
      prominent notification.
      <h4>Contact Us</h4>
      To exercise your rights or ask questions about this Data Privacy Policy,
      please contact us{' '}
      <a
        href="mailto:contact@endoscreen.org?subject=Data%20Privacy"
        style="color: black;"
      >
        here
      </a>
      .{' '}
    </p>

    <h3> Third Party Information</h3>
    <p>
      {' '}
      This app may contain links to third-party websites, services, or other
      events or activities that are not owned or controlled by us. We do not
      endorse or assume any responsibility for any such third-party sites,
      information, materials, products, or services.
      <br />
      If you access a third-party website from this app, you do so at your own
      risk, and you understand that this Disclaimer and our Terms of Service do
      not apply to your use of such sites. You expressly relieve us from any and
      all liability arising from your use of any third-party website, service,
      or content, including without limitation, any harm, loss, or damage of any
      sort incurred as a result of your dealings with such third parties.
    </p>
    <h3>Image Rights</h3>
    <p>
      All images, graphics, and other visual elements created by or for
      Endoscreen LLC ("we," "us," or "our") and displayed in the Endoscreen App
      are the exclusive property of Endoscreen LLC and are protected under
      applicable copyright laws.
      <h4>Licensed Images:</h4>
      Some images displayed within the Endoscreen App are used under license
      from third-party sources, such as Shutterstock. These images are
      copyrighted by their respective owners and are used by Endoscreen LLC
      under the terms of the applicable license. The use of these images does
      not imply any affiliation with or endorsement by these third-party
      sources. Unauthorized use, reproduction, or distribution of any images
      contained within the Endoscreen App is strictly prohibited and may result
      in legal action. <br />
      By using this App, you acknowledge and agree to these terms regarding
      image copyright and licensing.
      <br />
      <br />
    </p>
  </>
)
