import { FunctionComponent } from 'preact'
import { useCallback } from 'preact/hooks'
import { Col } from '~components/flexbox'
import { useInput } from '~hooks'

export const TextInputTab: FunctionComponent<{ id?: string }> = ({ id }) => {
  const [value, onInput] = useInput(sessionStorage.getItem('text') || '')

  const analyzeText = useCallback(() => {
    sessionStorage.removeItem('base64')
    sessionStorage.setItem('text', value)
    location.pathname = '/results'
  }, [value])

  return (
    <Col id={id}>
      <label for="ingredients">
        Enter ingredient name(s) here. <br />
        Multiple ingredients should be separated by a comma!
      </label>
      <textarea name="ingredients" {...{ onInput, value }} />
      <button onClick={analyzeText}>Analyze</button>
      <a href="resources#text" style="color: black;">
        How to Use?
      </a>
    </Col>
  )
}
