import { FunctionComponent } from 'preact'
import { Col } from '~components/flexbox'
import { Tabs } from '~components/Tabs'
import { VideoCaptureTab } from './VideoCapture'
import { TextInputTab } from './TextInput'
import { ImageUploadTab } from './ImageUpload'
import './index.scss'

const tabs = [
  { name: 'Take picture', pathname: '/' },
  { name: 'Enter text', pathname: '/text' },
  { name: 'Upload image', pathname: '/image' },
]

type Props = {
  active?: (typeof tabs)[number]['name']
}

export const CaptureInput: FunctionComponent<Props> = ({
  active = 'Take picture',
}) => {
  console.log('capture input')
  return (
    <Col id="capture-input">
      <Tabs tabs={tabs} active={active}>
        <VideoCaptureTab />
        <TextInputTab />
        <ImageUploadTab />
      </Tabs>
    </Col>
  )
}
