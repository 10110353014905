import { FunctionComponent } from 'preact'
import { useRef, useState, useEffect, useCallback } from 'preact/hooks'
import { Empty } from '~/common'
import { Col } from '~components/flexbox'

import './index.scss'

export const VideoCaptureTab: FunctionComponent<Empty> = () => {
  console.log('video capture')
  const video = useRef<HTMLVideoElement>(null)
  const canvas = useRef<HTMLCanvasElement>(null)
  const [running, setRunning] = useState(false)

  useEffect(() => {
    window.navigator.mediaDevices
      .getUserMedia({ video: { facingMode: 'environment' }, audio: false })
      .then((stream) => {
        if (video.current) {
          video.current.srcObject = stream
          video.current.play()
          setRunning(true)
        }
      })
      .catch(
        (err) => void console.error(`An error occurred starting video: ${err}`)
      )
  }, [])

  const [showCapture, setShowCapture] = useState(false)
  const captureImage = useCallback(() => {
    if (canvas.current && video.current) {
      const context = canvas.current.getContext('2d')
      const width = video.current.videoWidth
      const height = video.current.videoHeight
      canvas.current.width = width
      canvas.current.height = height
      context?.drawImage(video.current, 0, 0, width, height)
      setShowCapture(true)
    }
  }, [setShowCapture])

  const unCaptureImage = useCallback(() => {
    setShowCapture(false)
  }, [setShowCapture])

  const analyzeImage = useCallback(() => {
    sessionStorage.setItem(
      'base64',
      canvas.current?.toDataURL('image/png').replace(/^data.+base64,/, '') || ''
    )
    sessionStorage.removeItem('text')
    location.pathname = '/results'
  }, [canvas])

  return (
    <Col id="video-capture">
      <div id="instructions">
        Take a picture of your product's ingredient list. Make sure all of the
        text is visible!
      </div>
      <video ref={video} style={{ display: showCapture ? 'none' : 'block' }} />
      <canvas
        ref={canvas}
        style={{ display: showCapture ? 'block' : 'none' }}
      />
      {running ? (
        showCapture ? (
          <>
            <button onClick={unCaptureImage}>Retake</button>
            <button onClick={analyzeImage}>Analyze</button>
          </>
        ) : (
          <button class="secondary" onClick={captureImage}>
            Capture
          </button>
        )
      ) : (
        <div>Loading camera...</div>
      )}
      <a href="resources#howto-section" style="color: black;">
        How to Use?
      </a>{' '}
      <a href="resources#camera-trouble" style="color: black;">
        Camera trouble?
      </a>
    </Col>
  )
}
