import { h } from 'preact'
import { useState } from 'preact/hooks'
import './modal.scss'

interface SurveyModalProps {
  isOpen: boolean
  onClose: () => void
}

export const SurveyModal = ({ isOpen, onClose }: SurveyModalProps) => {
  const [formData, setFormData] = useState({
    age: '',
    gender: '',
    racialBackground: '',
    awareness: '',
    improvement: '',
  })

  const handleInputChange = (
    event: h.JSX.TargetedEvent<HTMLInputElement | HTMLSelectElement, Event>
  ) => {
    const { name, value } = event.currentTarget
    setFormData({ ...formData, [name]: value })
  }

  const handleFormSubmit = async (
    event: h.JSX.TargetedEvent<HTMLFormElement, Event>
  ) => {
    event.preventDefault()
    try {
      /*
        Add call using formData k:v here.
        1. issue with "no_change" or empty values when submitted that needs to be addressed.
           - do we need values? what can we set to default or respond with "error please enter values or select do not wish to disclose"?
        2. address whether we need to allow form submission based on session cookie or ip to eliminate abusing this.

      // console.log(formData)
      */
      try {
        const response = await fetch('/api/surveysubmit', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        })

        if (response.ok === true) {
          console.log('Survey data submitted successfully')
          onClose()
        } else {
          console.log('Failed to submit survey data')
        }
      } catch (err) {
        // fetch doesn't throw for http errors based on status code,
        // but can due to network failure or browser permissions
        console.log('Failed to get response from survey data submission')
      }
    } catch (error) {
      console.log('An error occurred while submitting the survey:', error)
    }
  }

  if (!isOpen) {
    return null
  }

  return (
    <div className="modal">
      <form onSubmit={handleFormSubmit}>
        <div class="centered-text">
          <h1>A moment of your time...</h1>
          <p>
            We are working to reach people about the reality of EDCs. <br />
            Tell us about yourself so we can reach more people like you!
          </p>
        </div>
        <br />
        <div>
          <label htmlFor="age">What is Your Age?:</label>
          <br />
          <input
            type="number"
            id="age"
            name="age"
            onChange={handleInputChange}
          />
        </div>
        <br />
        <div>
          <label htmlFor="gender">
            How would you describe your gender identity?:
          </label>
          <br />
          <select id="gender" name="gender" onChange={handleInputChange}>
            <option value="blank">...</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="NB">Non-Binary</option>
            <option value="NoAnswer">I do not wish to answer</option>
          </select>
        </div>
        <br />
        <div>
          <label htmlFor="race">
            How would you describe your racial/ethnic background?:
          </label>
          <br />
          <select
            id="race"
            name="racialBackground"
            onChange={handleInputChange}
          >
            <option value="blank">...</option>
            <option value="BlackAfrican">Black or of African descent</option>
            <option value="EastAsian">East Asian</option>
            <option value="HispanicLS">
              Hispanic, Latinx or of Spanish Origin
            </option>
            <option value="IndigenousAIAN">
              Indigenous, American Indian or Alaska Native
            </option>
            <option value="MiddleEastNAM">
              Middle Eastern or North African Native
            </option>
            <option value="HawaiianPI">Hawaiian or Pacific Islander</option>
            <option value="SouthAsian">South Asian</option>
            <option value="SoutheastAsian">Southeast Asian</option>
            <option value="WhiteEuropean">White or European</option>
            <option value="NoAnswer">I do not wish to answer</option>
          </select>
        </div>
        <br />
        <div>
          <label htmlFor="old_awareness">
            Were you aware of EDCs before using this app?
          </label>
          <br />
          <input
            type="radio"
            id="yes"
            name="awareness"
            value="yes"
            onChange={handleInputChange}
          />
          <label htmlFor="yes">Yes </label>
          <input
            type="radio"
            id="no"
            name="awareness"
            value="no"
            onChange={handleInputChange}
          />
          <label htmlFor="no">No </label>
        </div>
        <br />
        <div>
          <label htmlFor="new_awareness">
            How would you rate your awareness of EDCs after using this app?
          </label>
          <br />
          <input
            type="radio"
            id="no_change"
            name="improvement"
            value="no_change"
            onChange={handleInputChange}
          />
          <label htmlFor="no_change">The same </label>
          <input
            type="radio"
            id="better"
            name="improvement"
            value="better"
            onChange={handleInputChange}
          />
          <label htmlFor="better">Somewhat higher </label>
          <input
            type="radio"
            id="much_better"
            name="improvement"
            value="much_better"
            onChange={handleInputChange}
          />
          <label htmlFor="much_better">A lot higher </label>
        </div>
        <br />

        <div className="button-container">
          <button type="submit">Submit Survey</button>
          <button type="button" onClick={onClose}>
            Close
          </button>
        </div>
      </form>
    </div>
  )
}
