import { useRef, useState, useEffect } from 'preact/hooks'

type PieChartData = {
  label: string
  value: number
  details: string
}

// prettier-ignore
const data: PieChartData[] = [
  { label: 'Adrenal', value: 172, details: 'The adrenal glands produce hormones that help regulate your metabolism, immune system, blood pressure, response to stress. EDCs can affect function of the adrenal glands, making us feel tired, weak, and leading to high or low blood pressure. In some cases, people may develop stress or anxiety disorders.' },
  { label: 'Cancer', value: 453, details: 'Cancer is a disease in which some of the bodys cells grow uncontrollably and spread to other parts of the body. EDCs are linked to an increased risk of certain cancers like breast, ovarian, prostate, testicular, and thyroid cancer.' },
  { label: 'Cellular', value: 475, details: 'Cell damage (or death) happens in response to stress that a cell suffers. EDCs can directly damage or kill the cells that make up our organs and tissues, underlying some of their most directly harmful or toxicological effects. They can also change the way certain cells function, leading to disease.' },
  { label: 'Cognitive', value: 330, details: 'The term "Cognitive-Behavioral" encompasses any psychological experience and its accompanying manifestation in an individual. This can include psychiatric conditions like schitzophrenia or behavioral disorders like ADHD. Because EDCs affect brain function, they can result in the development of learning, memory, and behavioral problems.' },
  { label: 'Genetic', value: 107, details: 'A genetic system is essentially a set of instructions that dictate our biological makeup - genes are segments of DNA that contain instructions for building our bodies and executing its functions. EDCs can influence the expression of genes involved in various biological processes, including development, metabolism, and reproduction. These genetic impacts can have far-reaching consequences, potentially leading to adverse health effects and extending to future generations.' },
  { label: 'Growth', value: 178, details: 'When pregnant women are exposed to EDCs, it interferes with their babys growth and development. This can cause birth defects, developmental delays. Many of the health effects described also manifest in children of women who are exposed to EDCs during pregnancy.' },
  { label: 'Heart', value: 48, details: 'The cardiovascular system provides circulation of blood through the body. EDCs increase the risk of heart disease, high blood pressure, and stroke by affecting our hearts and blood vessels.' },
  { label: 'Hepatic', value: 312, details: 'The hepatic system - primarily composed of the liver and accompanying blood vessels - detoxifies blood, metabolizes glucose and drugs, and synthesizes proteins. EDCs cause liver damage and increase the risk of conditions such as non-alcoholic fatty liver disease (NAFLD) or altering the liver’s ability to metabolize drugs and other substances. This can lead to toxic buildup in the liver and other organs, causing a range of adverse health effects.' },
  { label: 'Immune', value: 219, details: 'The immune system is a complex network of organs, cells and proteins that defends the body against infection and protects its cells. EDCs can disregulate the immune system, making  it over- or under-active. A disrupted immune system may make our body more susceptible to infections and illnesses, or increase the risk of developing autoimmune disorders.' },
  { label: 'Metabolic', value: 491, details: 'The metabolic system encompasses all of the actions and reactions that occur to provide the body and its cells with energy. EDCs disrupt the way our body processes food and energy, increasing the risk of developing metabolic disorders like obesity, diabetes, and high cholesterol. They also affect cellular metabolic processes, leading to damaged or dysfunctional cells.' },
  { label: 'Neural', value: 426, details: 'The nervous system sends messages back and forth between the brain and body, acting as an executive system that controls our experiences and biological functions. EDCs can affect the structure and function of the brain, possibly resulting in neurological disorders ranging from functional disruptions like epilepsy or Alzheimers disease to cognitive-behavioral disruptions like depression or ADHD.' },
  { label: 'Pregnancy', value: 261, details: 'Pregnancy is the period during which a fetus develops inside a womans womb. EDCs disrupt hormonal signaling during pregnancy and lead to complications like preeclampsia (high blood pressure), preterm birth, and in rare cases miscarriage.' },
  { label: 'Sexual', value: 2203, details: 'The reproductive system consists of internal and external organs which regulate reproduction. In males it includes the testicals and penis, and in females it includes the ovaries and uterus. The reproductive system also includes important regulatory hormones like estrogens and androgens. EDCs affect how our reproductive system works, leading to adverse health effects like abnormal sexual development, hormone imbalances, and infertility. Men may have malformations of the testicles and penis, while women may experience increased rates of menstrual disorders.' },
  { label: 'Skeletal', value: 74, details: 'The skeletal system is your bodys central framework. It consists of bones and connective tissue, including cartilage, tendons, and ligaments. EDCs interfere with the development and maintenance of our bones, leading to problems like osteoporosis, arthritis, and fractures.' },
  { label: 'Thyroid', value: 366, details: 'The thyroid gland produces hormones that regulate the bodys metabolic rate, growth, and development. EDCs can disrupt with these hormones, causing a range of adverse health effects including weight changes, hair loss, and changes in heart rate and body temperature. The thyroid also partially regulates brain development in children, leading to cognitive and developmental problems if disrupted.' },
  { label: 'Tumors', value: 129, details: 'Tumours are groups of abnormal cells that form lumps or growths. EDCs increase the risk of both benign and cancerous tumor development.' },
];

const PieChart = () => {
  const [selectedSlice, setSelectedSlice] = useState<PieChartData | null>(null)
  const [selectedSliceIndex, setSelectedSliceIndex] = useState<number | null>(
    null
  ) // New state for slice index

  const maxWidth = 400
  const maxHeight = 400

  let width = window.innerWidth * 0.9
  let height = window.innerWidth * 0.9
  const padding = 30
  width = Math.min(width, maxWidth)
  height = Math.min(height, maxHeight)
  const radius = Math.min(width, height) / 2 - padding
  // prettier-ignore
  const colors = [
    "#e6194B", "#3cb44b", "#ffe119", "#4363d8", "#e63900", "#42d4f4",
    "#f032e6", "#469990", "#e6beff", "#9A6324", "#800000", "#808000",
    "#fabebe", "#000075", "#006666", "#99bbff", "#000000"
  ];

  let startAngle = 0
  const total = data.reduce((sum, entry) => sum + entry.value, 0)

  const colorGenerator = (index: number) => colors[index]

  const getSliceRadius = (index: number) => {
    if (selectedSliceIndex === index) return radius * 1.1
    return radius
  }

  const handleSliceClick = (index: number, entry: PieChartData) => {
    setSelectedSlice(entry)
    setSelectedSliceIndex(index)
  }

  useEffect(() => {
    if (selectedSlice && contentRef.current) {
      contentRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [selectedSlice])

  const Legend = () => (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '.5em',
      }}
    >
      {data.map((entry, index) => (
        <div
          key={index}
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '30%',
            cursor: 'pointer',
          }}
          onClick={() => {
            setSelectedSlice(entry)
            setSelectedSliceIndex(index) // Set the slice index on click
          }}
        >
          <div
            style={{
              width: '1.5em',
              height: '1em',
              backgroundColor: colorGenerator(index),
              marginRight: '.5em',
              marginLeft: '1em',
            }}
          />
          <div>{entry.label}</div>
        </div>
      ))}
    </div>
  )
  const contentRef = useRef<HTMLDivElement | null>(null)
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Legend />
      <div style={{ display: 'flex', flex: 1 }}>
        <div style={{ flex: 1 }}>
          <svg width={width} height={height}>
            <defs>
              <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
                <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
                <feOffset dx="2" dy="2" result="offsetblur" />
                <feMerge>
                  <feMergeNode />
                  <feMergeNode in="SourceGraphic" />
                </feMerge>
              </filter>
            </defs>
            <g transform={`translate(${width / 2},${height / 2})`}>
              {data.map((entry, index) => {
                const sliceAngle = (entry.value / total) * 2 * Math.PI
                const endAngle = startAngle + sliceAngle
                const x1 = getSliceRadius(index) * Math.sin(startAngle) // <-- Updated here
                const y1 = -getSliceRadius(index) * Math.cos(startAngle) // <-- Updated here
                const x2 = getSliceRadius(index) * Math.sin(endAngle) // <-- Updated here
                const y2 = -getSliceRadius(index) * Math.cos(endAngle)
                const verticalOffset = 50
                const midAngle = startAngle + sliceAngle / 2
                const labelRadius = 0.8 * radius
                const labelX = labelRadius * Math.sin(midAngle)
                const labelY =
                  -labelRadius * Math.cos(midAngle) - verticalOffset
                const largeArcFlag = sliceAngle > Math.PI ? 1 : 0
                const d = [
                  `M 0 0`,
                  `L ${x1} ${y1}`,
                  `A ${getSliceRadius(index)} ${getSliceRadius(
                    index
                  )} 0 ${largeArcFlag} 1 ${x2} ${y2}`, // <-- Updated here
                ].join(' ')

                startAngle += sliceAngle

                return (
                  <g key={index} onClick={() => handleSliceClick(index, entry)}>
                    <path
                      d={d}
                      fill={colorGenerator(index)}
                      filter="url(#shadow)"
                      style={{ transition: 'transform 0.3s ease' }}
                      transform={
                        selectedSliceIndex === index ? 'scale(1.04)' : undefined
                      }
                    />
                    {entry.label === 'Sexual' && (
                      <>
                        <text
                          x={labelX - 35}
                          y={labelY + 50}
                          fill="#000"
                          fontWeight="bold"
                        >
                          Reproductive Effects
                        </text>
                        <text
                          x={labelX - 15}
                          y={labelY + 70}
                          fill="#000"
                          textAnchor="center"
                          fontWeight="bold"
                        >
                          2203 papers
                        </text>
                      </>
                    )}
                  </g>
                )
              })}
            </g>
          </svg>
        </div>
      </div>
      {selectedSlice && (
        <div
          ref={contentRef}
          style={{
            padding: '.5em',
            border: '1px solid #ccc',
            borderRadius: '5px',
          }}
        >
          <h2>{selectedSlice.label}</h2>
          <p>{selectedSlice.details}</p>
        </div>
      )}
    </div>
  )
}

export default PieChart
