import { JSX } from 'preact'
import { useCallback, useState } from 'preact/hooks'

export const useInput = (
  initial: string
): [
  string,
  JSX.EventHandler<
    JSX.TargetedEvent<HTMLInputElement | HTMLTextAreaElement, Event>
  >
] => {
  const [value, setValue] = useState(initial)
  const onChange = useCallback(
    (evt: JSX.TargetedEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      void setValue(
        (evt.target as HTMLInputElement | HTMLTextAreaElement).value
      ),
    [setValue]
  )
  return [value, onChange]
}
