import { FunctionComponent } from 'preact'
import { CaptureInput } from './pages/CaptureInput'
import { ResultsScreen } from './pages/Results'
import { WhatAreEDCs } from './pages/EDCs'
import { Outcomes } from './pages/Outcomes'
import { About } from './pages/About'
import { Resources } from './pages/Resources'
import { Empty } from '~/common'
import { useState } from 'preact/hooks'
import { SurveyModal } from './components/SurveyModal'
import './app.scss'

type RouteProps = {
  path: RegExp | string
}

const Route: FunctionComponent<RouteProps> = ({ path, children }) => {
  const regex = typeof path === 'string' ? new RegExp(`^${path}$`) : path
  return regex.test(window.location.pathname) ? <>{children}</> : null
}

const Link: FunctionComponent<{ href: string }> = ({ href, children }) => {
  const className = window.location.pathname === href ? 'current' : undefined
  return <a {...{ href, className }}>{children}</a>
}

export const App: FunctionComponent<Empty> = () => {
  const [isModalOpen, setModalOpen] = useState(false)
  let landingPage = '/'

  if (window.location.pathname == '/text') {
    landingPage = '/text'
  } else if (navigator.userAgent.includes('iPhone')) {
    landingPage = '/image'
  }

  return (
    <>
      <header>
        <a href="/" aria-label="Link to home">
          <img
            src="/assets/header.svg"
            class="header-svg"
            alt="Endoscreen header image, reading 'Endoscreen: Know What You're Using'"
          />
        </a>
        <nav>
          <Link href="/">Scan</Link>
          <Link href="/edcs">Learn</Link>
          <Link href="/outcomes">Health</Link>
          <Link href="/resources">Resources</Link>
        </nav>
      </header>
      <main>
        <Route path="^(/|/text|/image)$">
          <CaptureInput active={landingPage} />
        </Route>
        <Route path="/results">
          <ResultsScreen />
        </Route>
        <Route path="/edcs">
          <WhatAreEDCs />
        </Route>
        <Route path="/outcomes">
          <Outcomes />
        </Route>
        <Route path="/resources">
          <Resources />
        </Route>
        <Route path="/about">
          <About />
        </Route>
      </main>
      <footer>
        <button onClick={() => setModalOpen(true)}>Survey</button>{' '}
        <a
          href="/about"
          style={{
            color: '#cec3be',
            textDecoration: 'none',
            fontWeight: 'bold',
          }}
        >
          ⓒ Endoscreen, LLC
        </a>
      </footer>
      <SurveyModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </>
  )
}
