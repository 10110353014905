import { FunctionComponent } from 'preact'
import { JSXInternal } from 'preact/src/jsx'

import './flexbox.scss'

export const Row: FunctionComponent<JSXInternal.IntrinsicElements['div']> = ({
  children,
  ...rest
}) => (
  <div className="fl-row" {...rest}>
    {children}
  </div>
)

export const Col: FunctionComponent<JSXInternal.IntrinsicElements['div']> = ({
  children,
  ...rest
}) => (
  <div className="fl-col" {...rest}>
    {children}
  </div>
)
