import { type FunctionComponent, type JSX } from 'preact'
import { useCallback, useState } from 'preact/hooks'
import { Col } from '~components/flexbox'
import './index.scss'

export const ImageUploadTab: FunctionComponent<{ id?: string }> = () => {
  const [selectedFile, setSelectedFile] = useState<File>()
  const [imageURL, setImageURL] = useState<string>('')

  // file input listener
  const fileInput = useCallback(
    (event: JSX.TargetedEvent<HTMLInputElement>) => {
      const target = event.target
      if (target instanceof HTMLInputElement) {
        const selectedFile = target.files?.[0]
        setSelectedFile(selectedFile)
        if (selectedFile) {
          // Display a preview of the selected image

          const imagePreview = document.getElementById(
            'previewImage'
          ) as HTMLImageElement
          imagePreview.src = URL.createObjectURL(selectedFile)

          const reader = new FileReader()
          reader.readAsDataURL(selectedFile)
          reader.onload = () => {
            setImageURL(reader.result as string)
          }
        } else {
          console.log('failed to select file')
        }
      }
    },
    [setSelectedFile, setImageURL]
  )

  // upload to google vision (stolen from videocapture)
  const uploadButton = useCallback(() => {
    if (selectedFile) {
      sessionStorage.setItem(
        'base64',
        imageURL.replace(/^data.+base64,/, '') || ''
      )
      sessionStorage.removeItem('text')
      location.pathname = '/results'
    } else {
      console.log('failed to upload image')
    }
  }, [imageURL, selectedFile])

  return (
    <Col id="image-upload">
      <div id="instructions">
        <img
          src="/assets/hint.svg"
          class="iphone-svg"
          alt="iPhone camera is accessible under the upload tab."
        />
        Upload images of ingredient lists saved to your device.
      </div>
      <input type="file" id="fileInput" onChange={fileInput} accept="image/*" />
      <img id="previewImage" />
      <button id="uploadButton" onClick={uploadButton}>
        Analyze
      </button>
      <a href="resources#upload" style="color: black;">
        How to Use?
      </a>{' '}
      <a href="resources#camera-trouble" style="color: black;">
        Camera trouble?
      </a>
    </Col>
  )
}
