import { useState } from 'preact/hooks'
import { FunctionComponent } from 'preact'

interface ToggleButtonProps {
  body: string
}

export const ToggleButton: FunctionComponent<ToggleButtonProps> = ({
  body,
}) => {
  const [infoVisible, setInfoVisible] = useState(false)

  return (
    <div>
      <button
        className="info-insert"
        onClick={() => setInfoVisible(!infoVisible)}
      >
        <h3>{body}</h3>
      </button>
      {infoVisible ? (
        <div style={{ color: 'black', display: 'block' }}>
          <img
            src="/assets/reliability.svg"
            class="reliability-svg"
            alt="On average, EDCs in our database have 14 research paper hits."
          />
        </div>
      ) : null}
    </div>
  )
}
