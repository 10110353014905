import { FunctionComponent } from 'preact'
import { Empty } from '~/common'
import PieChart from '~components/piechart'
import './textpage.scss'

export const Outcomes: FunctionComponent<Empty> = () => (
  <>
    <h3 class="centered-text">
      <img
        src="/assets/outcomes_header.svg"
        class="outcomes-svg"
        alt="EDCs affect many parts of our lives and health."
      />
      Endocrine disruptors affect many parts of our lives and health.{' '}
    </h3>
    <div class="centered-text">
      The piechart below shows the relative distribution of research about EDCs.
    </div>
    <br />
    <h4 class="centered-text">
      Click items on the legend to reveal more information about the health
      effects of EDCs:
    </h4>
    <br />
    <p>
      <PieChart />
    </p>
  </>
)
