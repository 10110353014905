import { FunctionComponent } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import ky from 'ky'
import { ApiChemicalResultsResponse, ChemicalInfo, Empty } from '~/common'
import { Col } from '~components/flexbox'
import { Accordion } from '~components/Accordion'
import { ToggleButton } from '~components/Toggle'
import './Results.scss'

const OutcomeIcon = (outcome: string): string => {
  switch (outcome) {
    case 'Reproductive':
      return 'Reproductive Disorders'
    case 'Genetic':
      return 'Genetic Alterations'
    case 'Metabolic':
      return 'Metabolic Syndromes'
    case 'Pregnancy':
      return 'Pregnancy Complications'
    case 'Developmental':
      return 'Developmental Disorders'
    case 'Adrenal':
      return 'Adrenal Disruptions'
    case 'Cell damage':
      return 'Cellular Disruptions'
    case 'Cancer':
      return 'Endocrine-Mediated Cancers'
    case 'Neurological':
      return 'Neurological Disruptions'
    case 'Immune':
      return 'Immune Impairments'
    case 'Thyroid':
      return 'Thyroid Disruptions'
    case 'Cardio-Vascular':
      return 'Cardio-Vascular Diseases'
    case 'Cognitive-Behavioral':
      return 'Cognitive-Behavioral Disorders'
    case 'Digestive':
      return 'Digestive Problems'
    case 'Hepatic':
      return 'Liver Disease'
    case 'Skeletal':
      return 'Skeletal Disruptions'
    case 'Tumors':
      return 'Tumors'
    case 'Inflammation':
      return 'Inflammation'
    case 'Respiratory':
      return 'Respiratory Disruptions'

    default:
      return outcome
  }
}

const OutcomeBlurb = (outcome: string): string => {
  switch (outcome) {
    case 'Reproductive':
      return 'menstrual irregularities, imbalances of reproductive hormones like estrogen or testosterone, and infertility.'
    case 'Genetic':
      return 'DNA damage that increases the risk of cancer and/or hereditary genetic disorders. Genes regulate the way our body works, and genetic disruptions often underlie many of the health effects of EDCs. Moreover, these changes can be passed on to future generations.'
    case 'Metabolic':
      return 'obesity, diabetes, and high cholesterol.'
    case 'Pregnancy':
      return 'preeclampsia (high blood pressure), preterm birth, and miscarriage.'
    case 'Developmental':
      return 'birth defects, physical and cognitive developmental delays, and ultimately many of the health disruptions described in this app.'
    case 'Adrenal':
      return 'high or low blood pressure and fatigue. Altered cortisol levels, the primary stress hormone produced by the adrenal glands, can increase susceptibility to stress-related disorders.'
    case 'Cell damage':
      return 'rapid cell proliferation (like in cancer or tumors) or cell damage that impaires functional activity and leads to disease.'
    case 'Cancer':
      return 'breast, ovarian, prostate, testicular, and thyroid cancer. These are the primary endocrine-regulated tissues of the body.'
    case 'Neurological':
      return 'altered structure and/or activity of the brain that can result in neurodegenerative diseases, cognitive disorders, and behavioral changes.'
    case 'Immune':
      return 'increased susceptibility to infections and illnesses, impaired immune function, and development of autoimmune disease.'
    case 'Thyroid':
      return 'hair loss, imbalence of thyroid hormones, and difficulty regulating weight, heart rate, and body temperature.'
    case 'Cardio-Vascular':
      return 'heart disease, high blood pressure, and stroke.'
    case 'Cognitive-Behavioral':
      return 'problems with learning, memory, and behavior. Some related diagnoses include autism spectrum disorder and ADHD.'
    case 'Digestive':
      return 'gastrointestinal inflammation, irritable bowel syndrome, altered gut microbiota, or difficulty absorbing nutrients.'
    case 'Hepatic':
      return 'liver damage, non-alcoholic fatty liver disease (NAFLD), and impaired ability to metabolize drugs and other substances. This can lead to toxic buildup in the liver and other organs.'
    case 'Skeletal':
      return 'osteoporosis, structural irregularities, and increased risk of fractures.'
    case 'Tumors':
      return 'both benign and potentially cancerous tumors.'
    case 'Inflammation':
      return 'asthma, allergies, and autoimmune diseases.'
    case 'Respiratory':
      return 'development of asthma and impaired respiratory function.'

    default:
      return outcome
  }
}

enum State {
  loading = 'LOADING',
  error = 'ERROR',
  ready = 'READY',
}
export const ResultsScreen: FunctionComponent<Empty> = () => {
  const [state, setState] = useState<State>(State.loading)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [chemicalData, setChemicalData] = useState<Array<ChemicalInfo>>([])

  useEffect(
    () =>
      void (async () => {
        const json = {
          base64: sessionStorage.getItem('base64') || undefined,
          text: sessionStorage.getItem('text') || undefined,
        }

        console.log('requesting...', { json })
        const response = (await ky
          .post('/api/chemicalResults', {
            throwHttpErrors: false,
            json,
          })
          .json()) as ApiChemicalResultsResponse

        console.log({ response })

        if (response.error === undefined) {
          setChemicalData(response.chemicals)
          setState(State.ready)
        } else {
          setErrorMessage(response.error)
          setState(State.error)
        }
      })(),
    []
  )

  if (state === State.loading) {
    return <div>Loading...</div>
  }

  if (state === State.error) {
    return (
      <>
        <div>Error!</div>
        <code>
          <pre>{JSON.stringify(errorMessage, null, 2)}</pre>
        </code>
      </>
    )
  }

  if (chemicalData.length == 0) {
    return (
      <Col id="results">
        <h2>No EDCs detected!</h2>
        <a href="/">Check another product</a>
      </Col>
    )
  }

  return (
    <Col id="results">
      <a href="/">Check another product</a>
      <h2>EDCs Detected:</h2>
      <ToggleButton body="ⓘ" />
      <ul>
        {chemicalData.map(({ name, hits, outcomes }) => (
          <li className="chemicalResult" key={name}>
            <div className="main">
              <h3 className="ingredient">
                {name}{' '}
                <span className="hits">
                  ({hits}){' '}
                  {hits > 50 && (
                    <img
                      src="/assets/evidence_strong.svg"
                      class="evidence-svg"
                      alt="ticker showing that 50+ research papers constitutes very strong evidence for EDCs"
                    />
                  )}
                  {hits <= 50 && hits > 25 && (
                    <img
                      src="/assets/evidence_good.svg"
                      class="evidence-svg"
                      alt="ticker showing that 25-50 research papers constitutes strong evidence for EDCs"
                    />
                  )}
                  {hits <= 25 && hits >= 14 && (
                    <img
                      src="/assets/evidence_moderate.svg"
                      class="evidence-svg"
                      alt="ticker showing that 14-24 research papers constitutes moderately strong evidence for EDCs"
                    />
                  )}
                  {hits < 14 && hits && (
                    <img
                      src="/assets/evidence_weak.svg"
                      class="evidence-svg"
                      alt="ticker showing that 2-14 research papers suggests a need for more evidence for EDCs"
                    />
                  )}
                </span>
              </h3>
            </div>

            <div>
              {name === 'fragrance' ? 
                `Fragrances can be made of 100s of chemicals. While we do not know exactly what this fragrance is made of,  ${hits} research article(s) link common components of Fragrance with:` :
                `${hits} research article(s) link ${name} with:`}
            </div>


            <ul className="outcomes">
              {Object.entries(outcomes)
                .sort((a, b) => b[1].chemicals.length - a[1].chemicals.length)
                .map(([outcome, matches]) => {
                  return (
                    <li key={`${name}.${outcome}`}>
                      <Accordion title={OutcomeIcon(outcome)}>
                        <p>
                          {' '}
                          {matches.chemicals.length} research article(s) link{' '}
                          {name} with{' '}
                          <a href="/outcomes" style="color: black;">
                            {OutcomeIcon(outcome)}
                          </a>
                          .{' '}
                        </p>
                        <p>
                          {' '}
                          {OutcomeIcon(outcome)} commonly associated with EDC
                          exposure can include {OutcomeBlurb(outcome)}
                        </p>
                        <div className="scrollable-box">
                          <ul>
                            <p>See the research:</p>
                            {matches.titles.map((title, index) => (
                              <li key={index}>
                                <a
                                  href={matches.links[index]}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style="color: black;"
                                >
                                  {title}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </Accordion>
                    </li>
                  )
                })}
            </ul>
          </li>
        ))}
        <div class="centered-text">
          {' '}
          How did we do? Please let us know by filling out the survey below!{' '}
        </div>
      </ul>
    </Col>
  )
}
