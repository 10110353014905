import { FunctionComponent } from 'preact'
import { Empty } from '~/common'
import { useState } from 'preact/hooks'
import './textpage.scss'

interface AccordionProps {
  title: string
  children: preact.ComponentChildren
  imageSrc: string
  imageSrcOpen?: string
}

export const Accordion: FunctionComponent<AccordionProps> = ({
  title,
  children,
  imageSrc,
  imageSrcOpen,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div>
      <div class="accordion" onClick={() => setIsOpen((prev) => !prev)}>
        {isOpen ? (
          <img src={imageSrcOpen} alt={title} />
        ) : (
          <img src={imageSrc} alt={title} />
        )}
      </div>
      {isOpen && (
        <div class="accordion-content" style={{ marginRight: '1em' }}>
          {children}
        </div>
      )}
    </div>
  )
}

export const WhatAreEDCs: FunctionComponent<Empty> = () => (
  <>
    <img
      src="/assets/health_header.svg"
      class="outcomes-svg"
      alt="Endocrine disruptors affect many parts of our lives and health."
    />
    <h3 class="centered-text">
      <br />
      Endocrine-disrupting chemicals, or EDCs, are chemicals found in every-day
      products that interfere with the body's endocrine system.
    </h3>
    <br />
    <a href="#targetSection">
      <img
        src="/assets/diagram.svg"
        class="diagram-svg"
        alt="The major organs of the endocrine system."
      />
    </a>
    <h3 id="targetSection" class="centered-text">
      Endocrine disruptors alter the function of these organs to cause a wide
      range of <a href="/outcomes">health</a> problems like infertility,
      diabetes, and certain cancers.
    </h3>
    <br />
    <h4 class="centered-text">
      Explore common sources of EDC exposure and learn how to avoid them:
    </h4>{' '}
    <br />
    <Accordion
      title="Food can contain EDCs from pesticides and coatings."
      imageSrc="/assets/food.svg"
      imageSrcOpen="/assets/food_open.svg"
    >
      <p>
        <ul>
          <li>Wash fruits and vegetables thoroughly before eating.</li>
          <li>Organic fruits and vegetables are exposed to fewer EDCs.</li>
          <li>
            Canned goods can contain internal coatings of BPA and other
            bisphenols. Choose fresh or frozen fruits and vegetables instead of
            canned; buy cans labeled "BPA-free" or “BP-free”.
          </li>
          <li>
            Avoid using pans with non-stick coating like teflon. These contain
            PFAS that leech into food at high heat. Opt for steel or other
            non-coated options.
          </li>
        </ul>
      </p>
    </Accordion>
    <Accordion
      title="Plastics are made from EDCs."
      imageSrc="/assets/plastics.svg"
      imageSrcOpen="/assets/plastics_open.svg"
    >
      <p>
        <ul>
          <li>
            Avoid using plastic containers for food and drinks. Opt for glass or
            stainless steel whenever possible.
          </li>
          <li>
            Wash plastics by hand instead of in the dishwasher. This has the
            added benefit of increasing the shelf-life of your plastic
            containers.
          </li>
          <li>
            Never heat food inside a plastic container or use plastic to contain
            acidic foods. The heat and acidity increase the rate at which
            chemicals get into food and drink.
          </li>
        </ul>
      </p>
    </Accordion>
    <Accordion
      title="Products contain EDCs from fragrance and packaging."
      imageSrc="/assets/products.svg"
      imageSrcOpen="/assets/products_open.svg"
    >
      <p>
        <ul>
          <li>
            Choose products labeled as unscented or free of fragrances. This is
            sometimes advertised as “paraben-free” and “phthalate-free”.
          </li>
          <li>
            Throw out expired personal care products like sunscreens and
            lotions, especially if they are in plastic and have been exposed to
            high temperatures (like being left in a hot car for weeks).
          </li>
          <li>
            Limit your exposure to skin lightening creams or chemical hair
            straighteners, especially when pregnant or trying to become
            pregnant.
          </li>
          <li>
            Read ingredient lists and use our app to become familiar with common
            EDCs in personal care products!
          </li>
        </ul>
      </p>
    </Accordion>
    <Accordion
      title="Cleaners contain EDCs."
      imageSrc="/assets/cleaners.svg"
      imageSrcOpen="/assets/cleaners_open.svg"
    >
      <p>
        <ul>
          <li>
            Use natural alternatives like vinegar and baking soda instead of
            chemical cleaners.
          </li>
          <li>
            Wear gloves when working with chemical cleaners, and a mask if you
            are not in a well-ventilated area. Wash your hands after use.
          </li>
          <li>
            Remove dust regularly. Home decor can contain EDC flame retardents
            (like Firemaster 550) that build up in household dust and get into
            the lungs.
          </li>
        </ul>
      </p>
    </Accordion>
    <Accordion
      title="Wastewater carries EDCs into our homes."
      imageSrc="/assets/water.svg"
      imageSrcOpen="/assets/water_open.svg"
    >
      <p>
        <ul>
          <li>
            Install a water filtration system in your home, or buy portable
            water filers.
          </li>
          <li>
            If you live near a wastewater treatment plant or other manufacturing
            (like fracking), contact your local representatives to voice your
            concerns.
          </li>
        </ul>
      </p>
    </Accordion>
  </>
)
